.webdev-container {
 
  margin-top: 50px;
  display: flex;
  flex-flow: column;
}

.webdev {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b4253;
}

.webdev .left-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webdev .right-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 50%;
}
.webdev h3 {
  margin-bottom: 20px;
}

.webdev p {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.webdev .lower-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.webdev .lower-content-left {
  width: 50%;
  background-color: #2b4253;
  border-radius: 10px;
  color: white;
  padding: 20px;
}

.webdev .lower-content-left ul {
  margin-left: 30px;
}

.webdev span {
  font-size: 0.7rem;
  line-height: 1.2rem;
  font-weight: 300;
}

.webdev .lower-content-right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webdev .lower-content-right img {
  width: 48%;
}

@media screen and (max-width: 800px) {
  .webdev {
    flex-flow: column;
   
    padding: 10px;
    align-items: center;
  }
  .webdev .left-container {
    width: 100%;
   
    justify-content: center;

  }

  .webdev .right-container {
    width: 100%;
  }
  .webdev .right-container .upper-content  {
    width: 100%;
    padding: 10px;
  }



  .webdev .lower-content {
    height: 100%;
    width: 100%;
  }

  .webdev .lower-content-left {
    width: 90%;
    margin-bottom: 20px;
  }
  .webdev .lower-content-right img {
    width: 100%;
    margin-left: 20px;
  }
}
