.hr-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.hr {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b4253;
}

.hr .left-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hr .left-container img {
    width: 50%;
    
}

.hr .right-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 50%;
}

.hr p {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}



@media screen and (max-width: 800px) {
  .hr {
    flex-flow: column;
  }
  .hr .left-container {
    width: 100%;
  }
  .hr .right-container {
    width: 100%;
    padding: 20px;
  }
}
