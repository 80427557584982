.tech-container {
  height: 200px;
  width: 100vw;
  background: rgb(231, 234, 237);
  background: linear-gradient(
    180deg,
    rgba(231, 234, 237, 1) 0%,
    rgba(255, 255, 255, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
}
.techstack {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.tech-container h3 {
  font-weight: 500;
  color: 1a3e59;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
}

.logo-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.logo {
  
  width: 200px;
  margin: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (max-width: 800px) {


    .tech-container {
        height: 700px;
    }

    .tech-container h3 {
        font-size: 1rem;
    }

    .logo-cont {
        flex-flow: column;
        
       

    }


}