.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  width: 100vw;
  margin: auto;
  background: url("../assets/wave.svg");
  background-position: bottom center;
  background-size: 100%;
  background-color: white;
  background-repeat: no-repeat;
}
.hero-container .hero {
  height: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.hero-container .hero .left-container {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.hero-container .hero .left-container h3 {
  text-transform: uppercase;
  color: #1a3e59;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 2px;
}

.hero-container .hero .right-container {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../assets/hero-computer.png") center center no-repeat;
  background-size: 90%;
  height: 100%;
}

.hero p {
  font-size: 0.8rem;
  color: #1a3e59;
  font-weight: 500;
  margin-bottom: 20px;
}

.hero span {
  font-size: 0.8rem;
  color: #1a3e59;
  font-weight: 700;
}

.cont-signup {
  width: 70%;
  display: flex;
}

.cont-signup input {
  width: 100%;
  height: 40px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #1a3e59;
  margin-bottom: 10px;
  padding-left: 10px;
}

.cont-signup button {
  width: 50%;
  height: 40px;
  border-radius: 0 5px 5px 0;
  border: 1px solid #1a3e59;
  background-color: #1a3e59;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .hero {
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .hero-container .hero .left-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .hero-container .hero .right-container {
    background-size: contain;
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 50px;
  }
}
