.footer-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  height: 600px;
  width: 100vw;
  margin: auto;
  background: url("../assets/footer.svg");
  background-position: bottom center;
  background-size: 100%;
  background-color: white;
  background-repeat: no-repeat;
  color: white;
}

.footer {
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer a {
    color: white;
}

.footer input {
    width: 300px;
    height: 40px;
    border-radius: 5px 0 0 5px;
    border: none;
    padding-left: 10px;
    margin-top: 10px;
}

.footer button {
    width: 100px;
    height: 40px;
    border-radius: 0 5px 5px 0;
    border: none;
    background-color: #1e425c;
    color: white;
    margin-top: 10px;
}
.footer p {
  font-size: 0.8rem;
  margin-top: 5px;
}

.footer .left-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-right: 50px;
  border-right: 1px solid white;
}

.footer .right-container {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  align-items: flex-start;
  justify-content: center;
  padding-left: 50px;
}

.footer-bottom {
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.footer-bottom .social-media {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.footer-bottom p {
  margin-top: 10px;
  font-size: 0.8rem;
}

@media screen and (max-width: 800px) {
  .footer-container {
    height: 1200px;
    background-color: #1e425c;
    background-position: bottom center;
    justify-content: center;
    align-items: flex-start;
  }

  .footer-container div {
    margin-top: 20px;
  }
  .footer {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .footer .left-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    border-right: none;
  }
  .footer .right-container {
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 0px;
  }

  .footer .card {
    width: 100vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .footer-bottom .social-media {
    width: 100vw;
  }
}
