* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}



.header-container {
  width: 100vw;
  position: sticky;
  top: 0;
  background-color: #ffffff41;

  backdrop-filter: blur(10px);
}



header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 90px;
  max-width: 1400px;
  margin: auto;

}

.logo-container {
  display: flex;
  align-items: center;
  background: url("../assets/infinity-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100px;
  aspect-ratio: 1;
}

.menu-icon {
  display: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.nav-container ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-container ul li {
  margin-right: 0.2rem;
  font-size: 0.7rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
}

.nav-container ul li a {
  color: #1a3e59;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-container ul li a:hover {
  color: #1a3e59;
  opacity: 0.8;
  border: 1px solid #1a3e59;
  border-radius: 0.5rem;
}

.nav-container button {
  background-color: #1a3e59;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 0.7rem;
}

.nav-container button:hover {
  background-color: #1a3e59;
  opacity: 0.8;
}

#webdev {
  scroll-margin-top: 100px;
}

#digitalMarketing, #hr, #dataScience {
  scroll-margin-top: 100px;
}

header p {
  font-size: 0.7rem;
  font-weight: 500;
  color: #1a3e59;
  margin-right: 1rem;
}

@media screen and (max-width: 800px) {
  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  

  .nav-container ul {
    position: absolute;
    top: 90px;
    left: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100vw;
    height: 320px;
    display: flex;
    background-color: aliceblue;
  }

  .nav-container button {
    display: block;
    margin-top: 10px;
  }

  .hidden {
    display: none;
  }

  header p {
    margin-top: 10px;
}
}
