.dataScience-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    

}
.dataScience {
    height: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b4253;
}

.dataScience .left-container {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;
}

.dataScience .right-container {
    height: 100%;
    width: 50%;
    display: flex;
   
    align-items: center;
    justify-content: center;
}

.dataScience .right-container img {
    width: 50%;
}

.dataScience p {
    font-size: 0.8rem;
    line-height: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
  }


@media screen and (max-width: 800px) {
    .dataScience {
        background-color: #ece3e1;
        flex-flow: column;
        padding-bottom: 20px;
    }

    .dataScience .left-container {
        width: 100%;
        padding: 20px;
    }

    .dataScience .right-container {
        width: 100%;
    }
    
}