.digital-container {
  display: flex;
  flex-flow: column;
  margin-top: 50px;
}

.digital {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b4253;
}
.digital p {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.digital .left-container {
  width: 50%;
}
.digital .right-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.digital .right-container img {
  width: 60%;
}



@media screen and (max-width: 800px) {
  .digital-container {
    margin-top: 0;
    background-color: #faf1ef;
  }
  .digital {
    flex-flow: column;
    padding: 10px;
  }
  .digital .left-container {
    width: 100%;
    padding: 10px;
  }
  .digital .right-container {
    width: 100%;
  }
}
