.mobileApps-container {
  width: 100vw;
  margin-top: 50px;
}

.mobileApps {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b4253;
}

.mobileApps .left-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobileApps .right-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.mobileApps p {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.mobileApps p:last-child {
  margin-top: 20px;
}

.mobileApps .left-container .lower-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.mobileApps .lower-content-left {
  width: 50%;
  background-color: #2b4253;
  border-radius: 10px;
  color: white;
  padding: 20px;
}

.mobileApps .lower-content-left ul {
  margin-left: 30px;
}

.mobileApps span {
  font-size: 0.7rem;
  line-height: 1.2rem;
  font-weight: 300;
}

.mobileApps .lower-content-right {
  width: 50%;
  display: flex;
  justify-content: center;
}

.mobileApps .lower-content-right img {
  width: 35%;
  object-fit: contain;
}

.mobileApps .right-container img {
  width: 40%;
}

#mobile {
  scroll-margin-top: 100px;
}

@media screen and (max-width: 800px) {
  .mobileApps {
    flex-flow: column;
    padding: 10px;
    align-items: center;
    background-color: #ece3e1;
    margin-top: 0;
  }
  .mobileApps .left-container {
    width: 100%;
    justify-content: center;
  }

  .mobileApps .left-container .upper-content {
    width: 100%;
    padding: 10px;
  }
  .mobileApps .right-container {
    width: 100%;
    justify-content: center;
  }
  .mobileApps .lower-content-left {
    width: 100%;
    margin-top: 20px;
  }
  .mobileApps .lower-content-right {
    width: 100%;
    margin-top: 20px;
  }
  .mobileApps .lower-content-right img {
    width: 48%;
    object-fit: contain;
  }
  .mobileApps .right-container img {
    margin-top: 20px;
    width: 48%;
  }
}
